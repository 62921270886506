.review {
  div {
    display: flex;
    justify-content: left;

    .thumbnail {
      cursor: pointer;
      max-width: 100px;
      height: auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: var(--image-bg-color);
      aspect-ratio: 1;
      margin: 0 auto;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }


    .description {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;

      .content {
        color: var(--color-3);
        padding-left: 1px;
        width: 70%; /* 부모 요소의 70% 너비 */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: var(--font-size-0-7) !important;
      }
    }
  }
}


@media (max-width: 768px) {
  .thumbnail {
    width: 20%;
  }
}


.gridReview {
  cursor: pointer;
  grid-gap: 60px 20px;
  display: grid;
  flex: 1 1;
  gap: 60px 20px;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  justify-content: start;
  margin: 3% 0;
  place-items: center;

  .item {
    padding: 0.5px;
    box-sizing: border-box;
    box-shadow: inset 0 0 2px 1px var(--border-color);
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .boardImage {
      flex: 1;
      display: flex;
      align-items: center;
      background-color: var(--image-bg-color);
      overflow: hidden;
      aspect-ratio: 1.05;
      position: relative;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        width: 100%;
        transition: transform 0.2s ease; /* 호버 효과에 부드럽게 전환되도록 설정 */
        flex: 1;
      }

      i{
        position: absolute;
        top : 3%;
        right: 3%;
        padding: 6px;
        color: var(--color-3);
        border-radius: 5px;
        background-color: var(--header-bgcolor);
      }
    }

    .info {
      padding: 3px 8px;
      .title {
        font-size: var(--font-size-0-9);
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 3px 0;
      }

      .content {
        font-size: var(--font-size-0-8);
        color: var(--color-3);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 1px 0;
      }

      .userName {
        font-size: var(--font-size-0-7);
        color: var(--color-5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
        padding: 0 5px;
      }
    }

    &:hover {
      transform: translateY(-0.5%);
    }
  }

  .productInfo {
    display: flex;
    padding: 8px;
    gap: 5px;

    .productImage {
      margin: auto 0;
      width: 20%;
      display: flex;
      align-items: center;
      background-color: var(--image-bg-color);
      overflow: hidden;
      aspect-ratio: 1;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        width: 100%;
        transition: transform 0.2s ease; /* 호버 효과에 부드럽게 전환되도록 설정 */
        flex: 1;
      }

    }

    & > div {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: var(--font-size-0-7);
        color: var(--color-5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      div:nth-child(2) {
        text-align: right;
      }

    }


  }


}



@media (max-width: 1024px) {
  .gridReview {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}

@media (max-width: 768px) {
  .gridReview {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 30px 10px;
    margin: 3% 0;
  }
}

@media (max-width: 480px) {
  .gridReview {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));

  }
}

