.view-canvas {
    position: relative;
    width: 100%;
    aspect-ratio: 1.2;
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
    background-color: #d6d6d6;
    max-height: 650px;
}

.view-canvas canvas {
    margin: 0 auto;
}

.view-canvas input {

    position: absolute;
    top: 10px;
    right: 10px;
    font-size: var(--font-size-1-3);
    padding: 8px 10px;
    border-radius: 3px;
    transform: rotate(180deg);
}
