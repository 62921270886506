.dashBoard {
    display: flex;
    justify-content: space-around;

    background-color: var(--bg-color-2);
    border: 1px solid var(--border-color-3);
    border-top: 2px solid black;
    border-radius: 0 0 5px 5px;
    padding: 20px 3px;
    align-items: center;
    font-size: var(--font-size-0-8);

    margin-top: 10px !important;
    p {
        text-align: center;
    }
    a p:first-of-type {
        font-weight: 600;
    }
}
