.categoryEdit {
    max-width: 1200px;
    margin: 0 auto;

    .categoryEditForm {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        gap: 80px 20px;
        /*justify-content: space-between;*/
        flex-wrap: wrap;
        margin: 5% auto;
        min-height: 200px;
        & > i {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--bg-color-3);
            text-align: center;
            padding: 10px 0;
            cursor: pointer;
        }
        & > .category {
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            overflow: hidden;
            p {
                white-space: nowrap;
                overflow: hidden;
            }
            & > i {
                background-color: var(--bg-color-3);
                text-align: center;
                padding: 5px;
                cursor: pointer;
            }
            
            .primaryCategory {
                display: flex;
                border-radius: 3px;
                font-size: var(--font-size-0-9) !important;
                padding: 2px 2px 1px 8px;
                margin: 4px 0;
                background-color: var(--bg-color-3);
                color: var(--color);
                
                & > p {
                    font-weight: bold;
                    background-color: var(--bg-color-3);
                    border-radius: 3px;
                    font-size: var(--font-size-0-9) !important;
                    color: var(--sub-color);
                    flex: 1;
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    
                    
                }
                & > i {
                    border-radius: 3px;
                    padding: 8px 6px;
                    cursor: pointer;
                    &:hover {
                        background-color: var(--hover-color-4) !important;
                        color: var(--color);
                        font-weight: 400;
                    }
                }
            }
            .secondaryCategory {
                display: flex;
                border-radius: 3px;
                font-size: var(--font-size-0-9) !important;
                padding: 4px 2px 4px 8px;
                margin: 3px 0;

                p {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                }
                
                i {
                    border-radius: 3px;
                    padding: 8px 6px;
                    cursor: pointer;
                    &:hover {
                        background-color: var(--hover-color-2);
                        color: var(--color);
                        font-weight: 500;
                    }

                }
            }
        }

    }
}



@media (max-width: 768px) {
    .categoryEditForm {
        grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    }
}

@media (max-width: 480px) {
    .categoryEditForm {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    }
}
