
.header {
    display: flex;
    gap: 5px;
    font-size: var(--font-size-1-2);
    align-items: center;
    font-weight: 500;
    color: var(--color-3);
    .active {
        color: black;
        font-weight: 600;
    }

    i {
        color: var(--color-3);
        font-size: var(--font-size-1-6);

        &.active {
            color: var(--active-color-4);
        }
    }

}

.info{
    width: 100%;
    display: flex;
    gap: 10px;
    margin: 50px auto;

    & > div:first-child{
        flex: 7;
    }
    & > div:last-child{
        flex: 5;
    }
}


@media (max-width: 768px) {
    .info {
        display: block;
    }
}
