.dashboard-builder {
    max-width: 1600px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto; /* 자동 높이 조정 */
    gap: 7px;
    margin: 5px auto;
}

.dashboard-item {
    border: 1px solid #ddd;
    padding: 15px 5px 5px;
    overflow: hidden; /* 스크롤 방지 */
}

.dashboard-item .product-page-bar {
    margin: 20px 0 10px;
}

.custom-component {
    background-color: lightblue;
    padding: 10px;
}



@media (max-width: 1024px) {
    .dashboard-builder {
        grid-template-columns: repeat(1, 1fr);
    }
}
