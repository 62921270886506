.productContain {
  flex: 1;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  justify-content: start;
  gap: 60px 20px;

  margin: 3% 0;

  .productItem {
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .productImage {
      flex: 1;
      display: flex;
      align-items: center;
      background-color: var(--image-bg-color);
      overflow: hidden;
      aspect-ratio: 1.05;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        width: 100%;
        transition: transform 0.2s ease; /* 호버 효과에 부드럽게 전환되도록 설정 */
        flex: 1;
      }

      &:hover img {
        transform: scale(1.1); /* 이미지를 1.1배 확대 */
      }
    }

    .productInfo {
      line-height: 1.8;
      font-size: var(--font-size-0-9);

      & > * {
        padding: 5px 0 0;
      }

      & > [id^='product-title'] {
        line-height: 1.3;
        min-height: 20px;
      }

      & > [id^='product-price'] {
        font-weight: bold;
      }


    }
  }


}

.cartListItem {
  box-shadow: none !important;
}

.orderListItem {
  box-shadow: none !important;
  margin: 0 !important;
  padding: 0 !important;
  * {
    margin: 0 !important
  }
}

.productListItem {
  display: flex;
  padding: 5px;
  box-shadow: inset 0 0 2px 1px var(--border-color);
  margin: 5px 0;

  .productImage {
    display: flex;
    align-items: center;
    background-color: var(--image-bg-color);
    width: 140px;
    aspect-ratio: 1.1;
    overflow: hidden;
    cursor: pointer;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      width: 100%;
    }


  }

  .productInfo {
    width: 100%;
    text-align: left;
    flex: 1;
    line-height: 1.8;
    font-size: var(--font-size-0-9);
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      cursor: pointer;
    }

    .title {
      font-size: var(--font-size-1);
      font-weight: 600;


    }

    .title.order{
    width: 100%;
      display: flex;
  align-items: center;
      justify-content: space-between;
      p{
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }
    }

    .price {
      display: flex;
      gap: 10px;
      justify-content: start;
    }

    .category {
      color: var(--color-4);
      font-size: var(--font-size-0-8);
    }

    a > div {
      display: flex;
      align-items: center;
      font-size: var(--font-size-0-8);
      padding: 0 5px;
    }

    & > div {
      justify-content: end;
    }

    & > [id^='product-id'] {
      margin-top: 10px;
      border-bottom: 2px solid var(--border-color);
    }
  }

}

.productIcon {
  padding: 0 !important;
  text-align: right;
  display: flex;

  & > div {
    flex: 1;
    display: flex;
    justify-content: left;
    gap: 5px;
    font-size: var(--font-size-0-9) !important;
    align-items: center;
  }

  & > i {
    font-size: var(--font-size-1-1);
    cursor: pointer;
    color: var(--color-3);
    margin-right: 10px;

    &::before {
      margin: 0;
    }
  }
}


@media (max-width: 1024px) {
  .productContain {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));

    .productListItem .productImage {
      width: 110px;
    }
  }
}

@media (max-width: 768px) {
  .productContain {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 30px 10px;
    margin: 3% 0;
  }

  .productListItem .productImage {
    width: 90px;
  }
}

@media (max-width: 480px) {
  .productContain {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));

  }
  .productListItem .productImage {
    width: 70px;
  }
}
