/* Modal.css */
.modal {
    z-index: 1000;
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 4px;
    max-height: 80vh;
    width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    border: none;
    outline: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1100;
}

.modal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 30px;
    overflow: hidden;
    font-size: 1.1rem;
}

.modal-content > input, .modal-content  > select {
    padding: 12px;
    box-sizing: border-box;
    margin: 10px 0;
    border: 1px solid var(--border-color-2);
    border-radius: 3px;
    font-size: var(--font-size-1);
}

.modal-content h2 {
    padding: 30px 0;
    font-size: var(--font-size-1-6);
}

.modal-content #main-message {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: var(--font-size-1-2);
}

.modal-content #sub-message {
    color: var(--color-3);
    font-size: var(--font-size-1-1);
}

.modal-button {
    border-top: 1px solid var(--border-color);
    border-radius: 4px;
    display: flex;
}

.modal-button button {
    cursor: pointer;
    flex: 1;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    padding: 20px;
    font-weight: 500;
    font-size: var(--font-size-1-1);
}

.modal-button button:nth-of-type(2) {
    border-left: 1px solid var(--border-color);
}

.modal-close-button {
    position: relative;
}

.modal-close-button i {
    cursor: pointer;
    position: absolute;
    top: -50px;
    right: 0;
    width: 70px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    font-size: var(--font-size-1-1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 10px 0 15px;
}
.modal-close-button i::after {
    content: '닫기';
    font-weight: 700;
    font-style: normal;
}

.modal-custom-component {
    overflow: auto;
}

@media (max-width: 768px) {
    .modal-close-button i {
        width: 60px;
        height: 40px;
        border-radius: 5px;
    }
}

@media (max-width: 480px) {
    .modal {
        max-width: 94vw;
        max-height: 75vh;
    }
    .modal-content {
        padding: 10px;
    }
    .modal-close-button i {
        top: -40px;
        width: 50px;

        height: 30px;
        border-radius: 5px;
    }
}
