.cartItem {
    width: 100%;
    box-shadow: inset 0 0 2px 1px var(--border-color);
    position: relative;

    .action {
        align-items: center;
        display: flex;
        gap: 10px;
      padding: 5px 5px 0;

        input {
            padding: 7px 12px;
        }
    }

    .cartInfo {
        display: flex;
        justify-content: end;
        position: relative;

        input {
            font-size: var(--font-size-0-9);
            padding: 5px 10px;
            border-radius: 3px;
        }

        & > div:first-child {
            position: absolute;
            text-align: left;
            width: 70px;
            bottom: 10px;
            left: 15px;
        }

        & > div:last-child {
            flex: 1;
            max-width: 400px;
            padding: 0 10px 0 0;
        }
    }

    .productListItem {
        box-shadow: none;

        .info {
            margin: 0 15px 0 25px;
        }

        .productListIcon {
            display: none;
        }
    }

    .removeButton {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: var(--font-size-1-3);
    }
}
