.price {
    font-size: var(--font-size-1);
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    background-color: white;

    .info {
        top: 120px;
        position: sticky;
        box-shadow: inset 0 0 2px 1px var(--border-color);
        color: var(--color-3);
        font-weight: 600;
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            justify-content: space-between;
            margin: 0 20px;
            padding: 12px 0;
        }

        div:last-child {
            border-top: 1px solid var(--border-color);
        }

        .buttonPlace {
            justify-content: end;
            gap: 8px;

            input {
                font-size: var(--font-size-0-8);
                padding: 8px 12px;
            }
        }

        select {
            border-radius: 5px;
            margin: 10px 10px 5px;
            padding: 12px;
            box-sizing: border-box;
            font-size: var(--font-size-0-9);
        }
    }
}

@media (max-width: 768px) {
    .price {
        bottom: 0;
        position: sticky;
        margin: 5px 0;
    }
}
