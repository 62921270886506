.delivery-tracker h2{
    font-size: var(--font-size-1-2);
    padding: 0;
    margin: 5px 0;
    text-align: left;

}


.delivery-tracker h3{
    font-size: var(--font-size-1-1);
    font-weight: 500;
    color: var(--color-3);
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-color-3);
    text-align: left;
}

.delivery-tracker > div {
    text-align: left;
}
.delivery-tracker > div h4{
    position: relative;
    padding-left: 30px;
    font-size: var(--font-size-1-1);
}
.delivery-tracker > div h4 > span{
    color: var(--color-3);
    padding-left: 5px;
    font-size: var(--font-size-0-9);
}
.delivery-tracker > div h4 a{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--font-size-1-1);
    color: var(--active-color-4);
    text-align: center;
    width: 20px;
}
.delivery-tracker > div p{
    padding: 10px 0 10px 20px;
    margin: 10px 0 10px  10px;
    font-size: var(--font-size-0-9);
    position: relative;
    border-left: 1px solid var(--active-color-4);
}

.delivery-tracker > div:last-child p{

    border: none;
}

.delivery-tracker > div:nth-child(3) h4 a {
    animation: blink 1.2s infinite; /* 애니메이션 설정 */
}

@keyframes blink {
    0% {
        color: var(--active-color-4); /* 시작 색상 */
    }
    100% {
       color: var(--active-color); /* 끝 색상 */
    }
}

