
.orderCheckOut {
    width: 100%;
    display: flex;
    gap: 10px;
    margin: 50px auto;



    & > div:first-child {
        flex: 7;
    }

    & > div:last-child {
        flex: 5;
    }

}

@media (max-width: 768px) {
    .orderCheckOut {
        display: block;
    }
}
