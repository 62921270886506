.main-image-slide {
    background-color: #b6b6b6;
    margin-bottom: 60px;
}
.main-image-slide .image-carousel {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    max-width: 1600px;
}

.main-image-slide .image-carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    padding: 15px 10px;
    color: var(--color-2);
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
}
.main-image-slide .image-carousel-button i {
    color: var(--color-2);
}

.main-image-slide .image-list {
    width: 100%;
    height: 60vh;
    display: flex;
    transition: transform 0.5s ease;
}

.main-image-slide .image-list div {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    flex: 0 0 auto;
    background-color: #b6b6b6;
    overflow: hidden;
    align-items: center;
}

.main-image-slide .image-list div img {
    flex: 0 0 auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    width: 100%;
}

.main-image-slide .image-carousel .intro-text {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.4);
    text-align: center;
}

.main-image-slide .image-carousel .intro-text h1 {
    color: var(--color-2);
    font-size: var(--font-size-2-5) !important;
    font-weight: 1000;
}

.main-image-slide .image-carousel .intro-text p {
    padding: 30px;
    color: var(--color-2);
    font-size: var(--font-size-1-8) !important;
    font-weight: 600;
}

.main-image-slide .image-carousel .intro-text .link {
    display: flex;
    justify-content: center;
}
.main-image-slide .image-carousel .intro-text .link a {
    font-size: var(--font-size-1-2);
    padding: 13px 20px;
    margin: 10px;
    border-radius: 5px;
}
.main-image-slide .image-carousel .intro-text .link a[id^='product'] {
    color: black;
    background-color: white;
}

.main-image-slide .image-carousel .intro-text .link a[id^='board'] {
    color: var(--color-2);
    background-color: black;
    border: 1px solid white;
}
@media (max-width: 768px) {
    .main-image-slide .image-carousel .intro-text .link a {
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .main-image-slide .image-carousel .intro-text .link a {
        padding: 10px 16px;
    }
}
