.productDetail {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  .header {
    max-width: 1400px;
    display: flex;
    margin: 30px auto;

    .image {
      margin: 10px;
      max-width: 600px;
      min-width: 370px;
      width: calc(45vw);
    }

    .info {
      margin: 10px;
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      h4 {
        font-weight: 600;
        padding: 0 5px 10px;
      }

      .sizeSelector {
        font-size: var(--font-size-0-8);
        padding: 10px 8px;
      }

      table {
        font-size: var(--font-size-0-9);
        padding: 10px;

        tr {
          height: 3rem;

          th {
            text-align: left;
            width: 100px;
            font-weight: 500;
          }
        }
      }

      .title {
        margin: 10px 0;
        padding: 0 10px;
        font-weight: 500;
        font-size: var(--font-size-1-7);
      }

      .icon{
        padding: 0 10px;
      }


    }



    .action {
      display: flex;
      justify-content: space-between;

      input {
        width: 48%;
        padding: 15px;
        box-sizing: border-box;
        cursor: pointer;

        &[id='cart-button'] {
          border-radius: 3px;
          border: 1px solid var(--border-color-2) !important;
          background-color: white;
          transition: all 0.2s ease-in-out;
          &:hover{
            background-color: var(--hover-color-4);
            color: white;
          }
        }

        &[id='order-button'] {
          border-radius: 3px;
          background-color: black;
          color: var(--color-2);
          transition: all 0.2s ease-in-out;
          &:hover{
            background-color: var(--hover-color-4);
          }
        }
      }
    }
  }

  .menu {
    background-color: white;
    z-index: 100;
    display: flex;
    font-size: var(--font-size-1-1) !important;
    text-align: center;
    position: sticky;
    top: 0;

    div {
      flex: 1;
      border: 1px solid var(--border-color);
      background-color: var(--hover-color);
      padding: 15px 0;
      cursor: pointer;

      &[class='select-menu'] {
        background-color: white;
        border-bottom: none;
      }
    }
  }

  .main {
    max-width: 1000px;
    margin: 30px auto;
    padding-bottom: 50px;
    width: 96%;

  }

  .footer {
    border-top: 3px solid var(--border-color);
    margin: 0px auto 100px;

    &>div{
      margin-bottom: 60px;
    }

    #product-period {
      max-width: 1400px;
      margin: 0 auto;
    }
  }
}



@media (max-width: 768px) {
  .productDetail {
    .header {
      display: block;
      margin: 10px auto;
      width: 96%;

      .image {
        margin: 0 auto;
        width: calc(95.5vw);
        max-width: 700px;
        min-width: 0;
      }

      .info {
        margin: 60px 0 0;
      }

      .sizeSelector {
        padding: 10px 3px;
      }

      .sizeCountPlace
      .sizeItem
      .sizeCount {

        input {
          height: 25px;
          width: 45px;
        }

        button {
          width: 25px;
          height: 25px;
        }
      }
    }

    .menu div {
      padding: 11px 0;
    }
  }


}

@media (max-width: 480px) {
  .productDetail .menu div {
    padding: 8px 0;
  }
}
