.productList {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .productCategory {


    .productCategoryContain {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
      justify-content: start;
      gap: 15px 15px;
      margin-top: 20px;

      a {
        max-width: 200px;
        border: 1px solid var(--border-color);
        border-radius: 2px;
        padding: 7px 10px;

        text-align: left;
        font-size: var(--font-size-0-9);

        &:hover,
        &[id^='current'] {
          background-color: var(--hover-color);
        }
      }
    }
  }


  .productListInfo {
    display: flex;
    justify-content: space-between;
    padding: 20px 2px 10px 2px;
    border-bottom: 1px solid var(--border-color);
    align-items: center;

    select {
      text-align: left;
      font-size: var(--font-size-0-9);
    }

    .selectContainer {
      width: auto;
    }

    .productItemCount {
      p {
        display: inline;
        padding: 5px 15px;
        border: 2px solid var(--border-color);
        border-radius: 10px;
        margin-right: 5px;
        font-weight: bold;
        box-sizing: border-box;
        font-size: var(--font-size-1);
      }

      span {
        font-size: var(--font-size-0-8);
        color: var(--color-3);
      }

    }
  }
}


@media (max-width: 768px) {
  .productCategory {
    .productCategoryContain {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)) !important;
      gap: 5px 5px;
    }
  }

}

@media (max-width: 480px) {
  .productCategory {
    .productCategoryContain {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)) !important;
    }
  }
}



.productContain {
  flex: 1;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  justify-content: start;
  gap: 60px 20px;

  margin: 3% 0;

  .productItem {
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .productImage {
      flex: 1;
      display: flex;
      align-items: center;
      background-color: var(--image-bg-color);
      overflow: hidden;
      aspect-ratio: 1.05;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        width: 100%;
        transition: transform 0.2s ease;
        /* 호버 효과에 부드럽게 전환되도록 설정 */
        flex: 1;
      }

      &:hover img {
        transform: scale(1.1);
        /* 이미지를 1.1배 확대 */
      }
    }

    .productInfo {
      line-height: 1.8;
      font-size: var(--font-size-0-9);

      &>* {
        padding: 5px 0 0;
      }

      &>[id^='product-title'] {
        aspect-ratio: 6;
        line-height: 1.3;
      }

      &>[id^='product-price'] {
        font-weight: bold;
      }

      .productListIcon {
        padding: 0;

        i {
          font-size: var(--font-size-1-2);
          cursor: pointer;
          color: var(--color-3);
          margin-right: 10px;

          &::before {
            margin: 0;
          }
        }
      }

    }
  }

  .productListItem {
    display: flex;
    padding: 5px;
    box-shadow: inset 0 0 2px 1px var(--border-color);

    .productImage {
      display: flex;
      align-items: center;
      background-color: var(--image-bg-color);
      width: 140px;
      aspect-ratio: 1.1;
      overflow: hidden;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        width: 100%;
      }

      .productInfo {
        text-align: left;
        flex: 1;
        line-height: 1.8;
        font-size: var(--font-size-0-9);
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          font-size: var(--font-size-1);
          font-weight: 600;
        }

        &>[id^='product-id'] {
          margin-top: 10px;
          border-bottom: 2px solid var(--border-color);
        }
      }

      .productListIcon {
        padding: 0;
        text-align: right;

        i {
          font-size: var(--font-size-1-1);
          cursor: pointer;
          color: var(--color-3);
          margin-right: 10px;

          &::before {
            margin: 0;
          }
        }
      }
    }
  }

}




@media (max-width: 1024px) {
  .productContain {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));

    .productListItem .productImage {
      width: 110px;
    }
  }
}

@media (max-width: 768px) {
  .productContain {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 30px 10px;
    margin: 3% 0;

    .productList-item .productImage {
      width: 90px;
    }
  }
}

@media (max-width: 480px) {
  .productContain {
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));

    .productListItem .productImage {
      width: 70px;
    }
  }
}