/* ------------------------------  리스트 테이블 ------------------------------- */

.listTable {
    max-width: 94vw;
    border-collapse: collapse;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    font-size: var(--font-size-0-9);

    tr {
        cursor: pointer;

        &:hover {
            cursor: pointer;
            background-color: var(--active-color);
        }

        &>*:first-child{
            border-left: none;
        }
        &>*:last-child{
            border-right: none;
        }
    }

    th {
        padding: 15px;
        border-collapse: collapse;
        border: 1px solid var(--border-color-3);
        border-top: 2px solid black;
        background-color: var(--bg-color);
        font-weight: 600;
    }

    td {
        border-collapse: collapse;
        padding: 15px;
        border: 1px solid var(--border-color-3);

        &.title {
            overflow: hidden;
            /* 넘치는 내용을 숨깁니다 */
            text-overflow: ellipsis;
            /* 넘치는 내용을 ...으로 표시합니다 */
            white-space: nowrap;
            /* 텍스트를 한 줄로 표시합니다 */
        }

        &[class='icon-star']::before,
        &[class='icon-star-empty']::before {
            margin: 0 !important;
        }
    }

    .openLeft {
        border-left: none;
    }

    .openRight {
        border-right: none;
    }

    .center {
        text-align: center;
    }

    span {
        font-size: var(--font-size-0-7);
        padding-left: 20px;
        color: rgb(62, 62, 255);
    }

    tfoot {

        th {
            padding: 10px 0 0;
            text-align: right;
            border: none;
            background-color: white;
        }

        input {
            cursor: pointer;
            padding: 10px 15px;
            margin-left:7px ;
            background-color: white;
            border: 1px solid var(--border-color-2);
            border-radius: 3px;
            font-size: var(--font-size-0-9);
        }
    }

}


.empty{
    height: 150px;
}

.hidden {
    @media (max-width: 768px) {
        display: none;
    }
}


@media (max-width: 768px) {
    .listTable {
        max-width: 96vw;

        th {
            padding: 10px;
        }

        td {
            padding: 10px;
        }

        .hide {
            display: none;
        }

        tfoot input {
            margin: 0 0 0 5px;
        }
    }

}

@media (max-width: 480px) {
    .listTable {
        max-width: 96vw;
    }

    .list-table th {
        padding: 7px;
    }

    .list-table td {
        padding: 7px;
    }

    .list-table tfoot input {
        margin: 3px;
        padding: 8px 12px;
    }
}
