.loading {
    display: none;
    z-index: 1000;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);

    justify-content: center;
    align-items: center;

    img {
        width: 60px;
        height: 60px;
        transform: rotate(0.05deg);
    }
}

