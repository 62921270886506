.sizeSelector {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 10px 15px;
    font-weight: 600;
    justify-content: start;
    font-size: var(--font-size-0-8) !important;
    letter-spacing: 0.5px;

    div {
        border: 1px solid var(--border-color);
        padding: 10px 8px;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
        background-color: white;
    }


}

.active{
    background-color: var(--active-color-2) !important;
    color: var(--color-2) !important;
}

@media (max-width: 768px) {
    .sizeSelector {
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
        gap: 5px 10px;
        div {
            padding: 10px 4px;
        }
    }

}
@media (max-width: 480px) {
    .sizeSelector {
        grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    }
}
