.sizeCountPlace {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-0-8) !important;

  & * {
    transform: rotate(0) !important;
  }
  .cartItem {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    border: none !important;

    input{
      margin: 5px 0 !important;
    }
    span{
      display: none;
    }
    .count {

      max-width: 200px !important;
    }

  }
  .item {
    display: flex;
    gap: 10px;
    justify-content: end;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--border-color-2);

    .name {
      font-weight: 600;
      padding-left: 10px;
      width: 65px;
      text-align: left !important;
    }

    .count {
      display: flex;
      align-items: center;
      justify-content: end;
      flex:1;
      div {
        margin-right: 5px;
        display: flex;
        align-items: center;
      }

      span {
        text-align: right;
        padding-right: 10px;
        width: 30%;
      }

      input {
        width: 50px;
        height: 30px;
        padding: 5px;
        box-sizing: border-box;
        border: 1px solid var(--border-color-2);
        text-align: center;
      }

      button {
        cursor: pointer;
        width: 30px;
        height: 30px;
        padding: 5px;

        color: var(--color-3);
        background-color: var(--bg-color-3);
        border: 1px solid var(--border-color-2);
      }

    }

  }

  .totalPrice {
    font-size: var(--font-size-1-1) !important;
    font-weight: 600;
    padding: 20px 10px;
    text-align: right;
  }

  .cartTotalPrice{
    font-size: var(--font-size-1) !important;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .name{
    width: 50px !important;
  }
  .count  button {
    width: 25px !important;
    height: 25px !important;
  }

  .count  input {
    height: 25px !important;
    width: 45px !important;
  }
}


