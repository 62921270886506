.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 96%;

  p {
    text-align: left;
    padding: 5px;
  }

  h1 {
    margin: 20px 0;
    text-align: center;

    span {
      font-size: var(--font-size-0-8) !important;
      padding: 0 10px;
      color: var(--color-3);
    }
  }

  h3 {
    font-size: var(--font-size-1-3);
    border-bottom: 2px solid var(--border-color-3);
    padding: 10px;
    margin: 20px 0 15px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * {
      width: fit-content !important;
      margin: 0;
    }
  }

  select {
    width: 100%;
    margin: 5px 0;
    padding: 10px 14px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    border-radius: 3px;
  }

  input {
    width: 100%;
    color: var(--color);
    padding: 10px 14px;
    margin: 5px 0;
    border-radius: 3px;
    border: 1px solid var(--border-color);
    box-sizing: border-box;
  }

  :global(.react-datepicker-wrapper) {
    width: 100%;
    color: var(--color);
    box-sizing: border-box;
  }

  label {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-0-9) !important;
    text-align: left;
    padding: 15px 0px 0px 5px;
    align-items: center;

    p {
      padding: 0;
    }

    span {
      font-size: var(--font-size-0-8) !important;
      padding: 0 5px;
    }

    input {
      width: auto !important;
      margin: 0 !important;
      padding: 7px 13px !important;

      border: 1px solid var(--border-color) !important;

      font-size: var(--font-size-0-8) !important;
    }
  }

  .checkBox {
    margin: 5px 0 40px;
  }
}

.kakaoImage {
  background-color: rgb(254 229 0);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 6px;
  cursor: pointer;

  img {

    width: 53%;

    max-width: 100%;
    max-height: 100%;
    object-fit: contain;

  }
}

.naverImage {
  cursor: pointer;
  background-color: rgb(3 199 90);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  img {
    width: 50%;
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;

  }
}


.flex {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-0-9);
  margin: 10px 0;
  align-items: center;
  gap: 5px;

  .halfButton {
    width: 49%;
    padding: 10px 13px;
  }
}

.marginZero {
  margin: 0 !important;
}

.grayButton {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  box-sizing: border-box;
  color: white !important;
  border: none;
  background-color: var(--button-color);

  &:hover {
    border-color: var(--active-color-3);
    background-color: var(--active-color-2) !important;
  }
}

@media (max-width: 768px) {
  .form {
    input {
      padding: 8px 13px;
    }
  }
}

@media (max-width: 480px) {
}
