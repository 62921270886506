.privacy-outside {
    max-width: 1000px;
    background-color: #efefef;
    padding: 20px;
    margin: 0 auto;
}

.privacy {
    width: 100%;
    margin: 0 auto;
    line-height: 2;
    font-size: var(--font-size-0-9) !important;
    background-color: white;

    padding: 15px;
    position: relative;
    box-sizing: border-box;

    max-height: 1200px;
    overflow: auto;
}
.privacy li {
    list-style-type: decimal-leading-zero;
    list-style-position: inside;
}

.privacy ol {
}

.privacy ul {
}

@media (max-width: 768px) {
    .privacy-outside {
        padding: 15px;
    }
}
@media (max-width: 480px) {
    .privacy-outside {
        padding: 10px;
    }
}
