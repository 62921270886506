.footer {
    background-color: var(--bg-color-3);
    position: static;
    z-index: 0;
    bottom: 0;
    margin-top: 50px;

    hr {
        margin: 0;
    }
    .document {
        max-width: none;
        width: 100%;
        border-bottom: 1px solid var(--border-color-2);
        padding: 10px;
        div {
            display: flex;
            justify-content: start;
            gap: 15px;
            width: 96%;
            max-width: 1400px;
            margin: 0 auto;

            a {
                font-size: var(--font-size-0-9) !important;
                color: var(--color-4);
            }
        }
    }

    & > div {
        width: 96%;
        max-width: 1400px;
        margin: 0 auto;
        text-align: left;
        display: flex;
        justify-content: start;
        font-size: var(--font-size-0-8);
        letter-spacing: 1px;
        padding: 40px 0;
        align-items: center;
        box-sizing: border-box;

        .image {
            width: 25vw;
            max-width: 300px;

            img {
                width: 100%;
                opacity: 0.7;
            }
        }

        .info {
            div {
                display: flex;
                gap: 25px;
                padding: 4px 20px;
            }

            a {
                background-color: #c9c6c6;
                border-radius: 3px;
                color: #fff;
                padding: 3px;
            }
        }
    }
}

@media (max-width: 768px) {
}

@media (max-width: 480px) {
    .footer {
        padding: 10px 15px 40px;
        .image {
            width: 50% !important;
            margin: 10px 0;
        }
        div {
            display: block;
            padding: 5px 0 !important;
        }
    }
}
