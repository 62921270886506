
.addressItem {
    box-shadow: inset 0 0 2px 1px var(--border-color);
    padding: 20px;

    font-size: var(--font-size-0-8);
    margin: 5px 0;

    h3 {
        font-size: var(--font-size-1-1);
        display: flex;
        justify-content: space-between;
        margin: 0 0 20px;
        align-items: center;
        input {
            font-size: var(--font-size-0-9);
            margin: 0 0 0 5px;
            padding: 8px 12px;
        }
    }

    & >div {
        text-align: left;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin: 10px 0 15px;

        p {
            white-space: nowrap;
            min-width: 90px;
            font-weight: 600;
        }

        span {
            font-size: var(--font-size-0-8);
            word-break: break-all;
            overflow-wrap: break-word;
        }
    }
}


@media (max-width: 768px) {
    .address-item {
        padding: 12px;
    }
    .address-item > div p {
        min-width: 70px;
    }

    .address-form h3 input {
        padding: 7px 13px;
    }
}
