.address-search-view h3{

    font-size: var(--font-size-1-1);
    text-align: left;
    margin: 20px 3px;
}

.address-search-view .search-area{
  display: flex;
    align-items: center;
    border: 1px solid var(--border-color-2);
    margin: 10px 0;
    border-radius: 5px;
}


.address-search-view .search-area input{
    font-size: var(--font-size-1) !important;
    border: none;
    padding: 10px;
    margin: 0;
    flex: 1;
}

.address-search-view .search-area i{
    cursor: pointer;
    width: 40px;
    aspect-ratio: 1;
    border-left: 1px solid var(--border-color-2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.address-search-view .product-page-bar{
    margin: 0 !important;
    margin-top: 20px !important;
}


@media (max-width: 480px) {
    .address-search-view .search-area input {
    padding: 6px;
    }

}