.productItem {
    box-sizing: border-box;
    box-shadow: inset 0 0 2px 1px var(--border-color);
    padding: 12px;
    margin: 3px 0;
    display: flex;
    gap: 10px;
    font-size: var(--font-size-0-8);

    span:first-child {
        flex: 1;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    span {
        width: 14%;
        text-align: right;
    }
}

.title {
    font-weight: 600;
}

.todoInfo {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200;
    margin: 20px 0;
    margin-right: auto;

    font-size: var(--font-size-1);
    gap: 10px;

    div {
        margin: 10px;
        cursor: pointer;
        span {
            color: var(--color-3);
        }
    }

    div:hover{
        color: var(--active-color-4);
    }
}

.analytics {
    text-wrap: nowrap;
    margin-bottom: 30px !important;
    td,th {
        padding: 12px 10px;
    }
    td {
        text-align: right;
        padding: 12px 10px;
    }
    table{
        font-size: var(--font-size-0-8) !important;
    }
}

.graph {
    height: 75%;
    box-sizing: border-box;
    & > * {
        margin: 20px 0;
    }
}

