.info {
    box-sizing: border-box;
    box-shadow: inset 0 0 2px 1px var(--border-color);
    padding: 20px;
    font-size: var(--font-size-0-8);
    margin: 5px 0;
    width: 100%;

    h3 {
        align-items: center;
        border-bottom: 1px solid var(--border-color);
        display: flex;
        font-size: var(--font-size-1-1);
        justify-content: space-between;
        margin: 0 0 10px;
        padding: 0 0 10px;

        input {
            font-size: var(--font-size-0-9);
            padding: 7px 13px;
            margin: 0;
        }
    }

    &>input {
        font-size: var(--font-size-0-9);
        padding: 7px 13px;
        margin: 0;
        width: 100%;
    }


    .requestInfoItem {
        box-shadow: inset 0 0 2px 1px var(--border-color);
        padding: 15px;
        text-align: left;
        display: flex;
        gap: 10px;
        cursor: pointer;
        margin-bottom: 5px;

        h4 {
            font-size: var(--font-size-0-9);
        }

        p {
            font-size: var(--font-size-0-8);
            color: var(--color-3);
        }

        span {
            flex: 1;
            text-align: right;
            vertical-align: bottom;
            color: var(--active-color-4);
        }

    }
}

.infoFlex {
    h3 {
        margin: 0 0 15px;
    }

    &>div {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin: 10px 0 15px;

        p {
            white-space: nowrap;
            min-width: 60px;
            font-weight: 600;
            text-align: left;
        }

        span {
            flex: 1;
            font-size: var(--font-size-0-8);
            word-break: break-all;
            overflow-wrap: break-word;
            text-align: right;

            &.link {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.order {
    .productCount {
        font-size: var(--font-size-0-9);
        display: flex;
        justify-content: start;
        gap: 7px;
        margin: 5px 0;
        color: var(--color-4);
        flex-wrap: wrap;
    }

    &>div {
        padding: 5px 0;

        &:not(:nth-child(2)) {
            border-top: 1px solid var(--border-color);
        }
    }
}

.request {
    text-align: left;
    padding: 30px 0;

    &>div {
        h4 {
            position: relative;
            padding-left: 30px;
            font-size: var(--font-size-1-1);

            &>span {
                color: var(--color-3);
                padding-left: 5px;
                font-size: var(--font-size-0-9);
            }

            a {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: var(--font-size-1-1);
                color: var(--active-color-4);
                text-align: center;
                width: 20px;
            }
        }

        p {
            padding: 10px 0 10px 20px;
            margin: 10px 0 10px 10px;
            font-size: var(--font-size-0-9);
            position: relative;
            border-left: 1px solid var(--active-color-4);
        }
    }

    &>div:first-child h4 a {
        animation: blink 1.2s infinite;
        /* 애니메이션 설정 */
    }

    &>div:last-child p {
        display: none;
    }
}


@keyframes blink {
    0% {
        color: var(--active-color-4);
        /* 시작 색상 */
    }

    100% {
        color: var(--active-color);
        /* 끝 색상 */
    }
}

@media (max-width: 768px) {
    .info {
        padding: 15px;
    }
}