.orderTable {
  width: 100%;
  border-collapse: collapse;
  font-size: var(--font-size-0-8);
  margin: 0 auto;

  thead {
    tr:first-child th {
      border-top: 2px solid black;
    }

    tr:last-child th {
      border-bottom: 1px solid black;
    }
  }

  tr *:first-child {
    border-left: none;
  }

  tr *:last-child {
    border-right: none;
  }


  tbody {
    tr:nth-child(6n),
    tr:nth-child(6n - 1),
    tr:nth-child(6n - 2) {
      background-color: var(--bg-color-4);
    }

    tr {
      cursor: pointer;
    }

  }

  th {
    padding: 8px;
    border: 1px solid var(--border-color-2);
    background-color: var(--bg-color-3);
  }

  td {
    text-align: center;
    padding: 10px;
    border: 1px solid var(--border-color-2);
  }

  .center {
    text-align: center;
  }

  :global .empty-list{
    min-height: 150px;
  }

}



@media (max-width: 768px) {
  .orderTable {
    th {
      padding: 5px;
    }

    td {
      padding: 6px;
    }
  }
}


.orderItem {
  box-shadow: inset 0 0 2px 1px var(--border-color);
  padding: 10px;
  margin-bottom: 5px;

  .header {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-1);
    font-weight: 600;
    margin: 5px 0 15px;
    align-items: center;

    span {
      color: var(--color-3);
      font-size: var(--font-size-0-9);
    }

    input {
      padding: 7px;
      margin-left: 5px;
      font-size: var(--font-size-0-8);
      border-radius: 3px;
    }
  }

  .body {
    display: flex;
  }

  .thumbnail {
    flex: 2;
    display: flex;
    align-items: center;
    background-color: var(--image-bg-color);
    aspect-ratio: 1;
    max-width: 120px;
    min-width: 87px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: 0 auto;
    }
  }

  .info {
    flex: 8;
    font-size: var(--font-size-0-9);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    text-align: right;
    overflow: hidden;

    div {
      width: 100%;

      p:first-child {
        font-size: var(--font-size-1);
        margin: 10px 0;
        font-weight: 600;
      }

      p:last-child {
        color: var(--color-3);
      }
    }

    p {
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      cursor: pointer;
      color: var(--color-4);
      text-decoration: underline;
    }
  }
}


@media (max-width: 768px) {
  .order-history-item-thumbnail {
    flex: 3;
  }

  .order-history-item-info {
    flex: 7;
  }
}
