.view-product,
.mypage-account .wish-view {
    margin: 30px 0;
    max-height: 500px;
    height: auto;
    overflow: auto;
}

.mypage-account .view-product h2,
.mypage-account .wish-view h2 {
    font-size: var(--font-size-1-1);
    padding: 20px 5px 10px;
}

@media (max-width: 768px) {
    .mypage-account .user-info a {
        padding: 6px;
    }
}
