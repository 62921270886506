/* ------------------------------ 검색 조건 테이블 ------------------------------- */

.search {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto 25px;
    border-top: 2px solid black;
    font-size: var(--font-size-0-9);

    tbody {
        th {
            padding: 5px;
            border: 1px solid var(--border-color-2);
            background-color: var(--bg-color-3);
        }
        td {
            padding: 5px 10px;
            border: 1px solid var(--border-color-2);
        }
        select {
            width: 100%;
            padding: 10px;
            border-radius: 3px;
            font-size: var(--font-size-0-9);
        }
        input {
            padding: 10px;
            width: 100%;
            box-sizing: border-box;
            font-size: var(--font-size-0-9);
        }

        tr > *:first-child {
            border-left: none;
        }

        tr > *:last-child {
            border-right: none;
        }
    }
    tfoot {
        tr td {
            text-align: right;
        }

        input {
            margin: 5px;
            padding: 10px 20px;
            box-sizing: border-box;
            border-radius: 3px;
            font-size: var(--font-size-0-9);
        }
    }

    .flex {
        align-items: center;
        display: flex;
        justify-content: space-between;
        span {
            width: 20px;
            text-align: center;
        }
        :global .react-datepicker-wrapper {
            flex: 1;
        }
    }
}



@media (max-width: 768px) {
    .search {
        tbody {
            td {
                padding: 4px;
            }

            select {
                padding: 7px;
            }

            input {
                padding: 7px;
            }
        }
    }
}
