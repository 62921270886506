.search-product {
    width: 100vw;
    height: 200px;
    position: fixed;
    top: 0;
    right: 50%;
    background-color: var(--sub-header-bgcolor);
    z-index: 1000;
    border-bottom: 1px solid var(--border-color);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: all 0.35s ease;

    transform: translateY(-100%) translateX(50%);
}

.search-product h3 {
    font-weight: 600;
    margin-bottom: 30px;
}

.search-product div {
    position: relative;
}

.search-product div input {
    margin: 0 0 15px;
    border: none;
    width: 60vw;
    max-width: 300px;
    padding: 15px;
    border-bottom: 3px solid var(--border-color-2);
    outline: none;
}

.search-product div i {
    cursor: pointer;
    z-index: 1200;
    position: absolute;
    right: 0;
    top: 2px;
    transform: translate(-10px, 50%);
    font-size: var(--font-size-1-1) !important;
}
.search-product-bg {
    display: none;
    z-index: 900;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
}

.search-product .close-button {
    position: relative;
    width: 100%;
    max-width: 600px;
    background-color: rgba(0, 0, 0, 0) !important;
    display: flex;
    align-items: end;
    justify-content: end;
    margin-bottom: 20px;
}
.search-product .close-button i {
    cursor: pointer;
    top: -50px;
    right: 0;
    width: 60px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 5px 0 10px;
    font-size: var(--font-size-1-1) !important;
    margin: 10px 0 10px;
}
.search-product .close-button i::after {
    font-size: var(--font-size-0-9) !important;
    content: '닫기';
    font-weight: 700;
    font-style: normal;
}

@media (max-width: 768px) {
}

@media (max-width: 480px) {
    .search-product div i {
        top: 5px;
    }
}
