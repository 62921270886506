.tab {
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 10px;

  border-bottom: 1px solid var(--border-color);
  margin: 0 auto;
  align-items: center;
  text-align: center;
  a {
    font-size: var(--font-size-1-2);
    width: 160px;
    padding: 20px 5px 5px;
    &.active {
      font-weight: 600;
      border-bottom: 3px solid var(--border-color-2);
    }

  }
}
