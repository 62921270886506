.boardView {
    display: flex;
    flex-direction: column;
    text-align: left;

    &> label {
        font-size: var(--font-size-1);
        margin: 30px 0 5px 2px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &> div > i {
            font-size: var(--font-size-0-9);
        }
    }

    .textarea {
        padding: 12px 10px;
        box-shadow: inset 0 0 2px 1px var(--border-color);
        font-size: var(--font-size-0-9);
        margin-bottom: 10px;
    }

    .content {
        min-height: 100px;
    }

    .info {
        display: flex;
        justify-content: end;
        font-size: var(--font-size-0-8);
        gap: 10px;
        margin-right: 5px;
    }

}




.board-view .board-image-list {
    margin-bottom: 30px;
}

.board-view .product-list-item {
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .board-view h2 input {
        min-width: 50px;
        padding: 8px;
    }
}

@media (max-width: 480px) {
    .board-view h2 input {
        min-width: 50px;
        padding: 7px;
    }
}
