.cartList {
    width: 100%;
    display: flex;
    gap: 10px;
    margin: 50px auto;

    & > div:first-child {
        flex: 7;
    }

    & > div:last-child {
        flex: 5;
    }
}

@media (max-width: 768px) {
    .cartList {
        display: block;
    }
}

.cartItem {
    width: 100%;
    box-shadow: inset 0 0 2px 1px var(--border-color);
    position: relative;

    .action {
        align-items: center;
        display: flex;
        gap: 10px;
        padding-top: 5px;

        input {
            padding: 7px 12px;
        }
    }

    .cartInfo {
        display: flex;
        justify-content: end;
        position: relative;

        input {
            font-size: var(--font-size-0-9);
            padding: 5px 10px;
            border-radius: 3px;
        }

        & > div:first-child {
            position: absolute;
            text-align: left;
            width: 70px;
            bottom: 10px;
            left: 15px;
        }

        & > div:last-child {
            flex: 1;
            max-width: 400px;
            padding: 0 10px 0 0;
        }
    }

    .productListItem {
        box-shadow: none;

        .info {
            margin: 0 15px 0 25px;
        }

        .productListIcon {
            display: none;
        }
    }

    .removeButton {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: var(--font-size-1-3);
    }
}

.price {
    font-size: var(--font-size-1);
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    background-color: white;

    .info {
        top: 120px;
        position: sticky;
        box-shadow: inset 0 0 2px 1px var(--border-color);
        color: var(--color-3);
        font-weight: 600;
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            justify-content: space-between;
            margin: 0 20px;
            padding: 12px 0;
        }

        div:last-child {
            border-top: 1px solid var(--border-color);
        }

        .buttonPlace {
            justify-content: end;
            gap: 8px;

            input {
                font-size: var(--font-size-0-8);
                padding: 8px 12px;
            }
        }

        select {
            border-radius: 5px;
            margin: 10px 10px 5px;
            padding: 12px;
            box-sizing: border-box;
            font-size: var(--font-size-0-9);
        }
    }
}

@media (max-width: 768px) {
    .price {
        bottom: 0;
        position: sticky;
        margin: 5px 0;
    }
}
