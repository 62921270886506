.dropArea {
    position: relative;
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center !important;
    box-sizing: border-box;

    p {
        margin: 20px;
        text-align: center !important;
        font-size: var(--font-size-1-1);
    }

    .dragging {
        border-color: #818181;
    }


    .selectImage {
        display: inline-block;
        background-color: #000000;
        color: #fff;
        padding: 10px 20px;
        margin: 0 auto !important;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        font-size: var(--font-size-1-1);

        &:hover {
            background-color: #868686;
        }
    }


    .hoverView{
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 20px;
        width: 100%;
        height: 100%;
        display: none;
        background-color: rgba(0, 0, 0, 0.05);
    }

    .gallery {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 30px;

        .galleryItem {
            background-color: var(--image-bg-color);
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                width: 100%;
            }

            .removeImage {
                cursor: pointer;
                border-radius: 30%;

                color: white;
                background-color: black;
                width: 30px;
                height: 30px;
                z-index: 5;
                position: absolute;
                top: -5px;
                right: -5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: var(--font-size-1-2);
            }
        }

    }

}


@media (max-width: 768px) {
    #drop-area {
        padding: 15px;
    }
    #gallery {
        grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
    }
}
@media (max-width: 480px) {
    #drop-area {
        padding: 10px;
    }
    #gallery {
        grid-template-columns: repeat(auto-fill, minmax(65px, 1fr));
    }
}
