.userInfo {
    display: flex;
    justify-content: space-between;

    background-color: var(--bg-color-2);
    border: 1px solid var(--border-color-3);
    border-top: 2px solid black;
    border-radius: 0 0 5px 5px;
    padding: 20px 2.5%;
    align-items: center;
    font-size: var(--font-size-0-9);
    line-height: 1.8;

    text-align: left;
    margin-top: 10px !important;
    span {
        font-weight: 600;
    }
    a {
        cursor: pointer;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        font-size: var(--font-size-0-7);
    }

    div:first-child {
        flex: 1;
    }
    & > div:last-child {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 5px;
    }


}



.kakaoImage  {
    background-color: rgb(254 229 0);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 6px;
    cursor: pointer;
    img {

        width: 53%;

        max-width: 100%;
        max-height: 100%;
        object-fit: contain;

    }
}

.naverImage {
    cursor: pointer;
    background-color: rgb(3 199 90);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    img {
        width: 50%;
        cursor: pointer;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;

    }
}

