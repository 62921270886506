.whiteButton {
  border: 1px solid var(--border-color-2) !important;
  background-color: white !important;
  color: black !important;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    border-color: var(--active-color-3);
    background-color: var(--active-color-3) !important;
    color: white !important;
  }
}

.blackButton {
  background-color: black !important;
  color: white !important;
  transition: all 0.2s ease-in-out;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 3px;


  &:hover {
    border-color: var(--active-color-3);
    background-color: var(--active-color-3) !important;
  }
}

.whiteButton, .blackButton {
  font-size: var(--font-size-0-9);
  padding: 8px 13px;
}

@media (max-width: 768px) {
  .whiteButton, .blackButton {
    padding: 7px 10px;
  }
}