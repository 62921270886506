.content {
    margin: 30px auto 6%;
    width: 96%;
    height: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.title {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-1-5);
    font-weight: 700;
    margin: 30px 0 10px;
    border-bottom: 1px solid var(--border-color-2);
    padding: 10px 0;
    span {
        font-size: var(--font-size-0-9) !important;
        padding: 2px 10px 0;
        color: var(--color-3);
        display: flex;
        gap: 5px;
        flex: 1;
        align-items: center;
    }

    .buttonArea {
        display: flex;
        gap: 5px;

        input {
            margin: 0;
            font-size: var(--font-size-0-9);
            padding: 8px 14px;
        }
    }
}

.content {
    .content {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .content {
        width: 98%;
    }

    .buttonArea {
        input {
            padding: 7px 10px !important;
        }
    }
}
