.side-bar {
    z-index: 400;
}
.pc-side-bar {
    z-index: 400;
    position: fixed;
    top: 30%;
    right: 0;
    display: flex;
    flex-direction: column;
    background: white;
    color: var(--color-4);
    transition: all ease-in-out 0.2s ;
}

.pc-side-bar i {
    font-size: var(--font-size-1-3);
    border-radius: 1px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.pc-side-bar > * {
    border: 1px solid var(--border-color-2);
    border-bottom: none;
    box-sizing: border-box;
    cursor: pointer;
    width: 45px;
    height: 45px;
}

.pc-side-bar > *.close-border {
    border-bottom: 1px solid var(--border-color-2) !important;
}
.pc-side-bar img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    width: 100%;
}

.mobile-side-bar {
    z-index: 400;
    display: none;
    position: fixed;
    bottom: 10%;
    right: 3%;
    flex-direction: column;

    color: var(--color-4);

    overflow: hidden;
    transition: all 0.25s ease-in-out;
}

.mobile-side-bar i {
    font-size: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
}

.mobile-side-bar *.close {
    display: none;
}

.mobile-side-bar *.open:hover {
    border: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
}
.mobile-side-bar *.open {
    background-color: rgba(255, 255, 255, 0.8);
    color: var(--color-4);
    border: 1px solid var(--border-color-2);
}
.mobile-side-bar i.icon-up-open::before {
    margin-bottom: 5px;
}
.mobile-side-bar i.icon-plus::before {
    margin-top: 0.8px;
}
.mobile-side-bar > * {
    margin-top: 3px;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    width: 45px;
    height: 45px;
    overflow: hidden;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
}

.mobile-side-bar img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    width: 100%;
}

@media (max-width: 768px) {
    .pc-side-bar {
        display: none;
    }
    .mobile-side-bar {
        display: flex;
    }
}

@media (max-width: 480px) {
    .pc-side-bar {
        display: none;
    }
    .mobile-side-bar {
        display: flex;
    }
    .mobile-side-bar > * {
        width: 40px;
        height: 40px;
    }
}
