.make-form {
    width: 96vw;
    max-width: 900px;
    margin: 0 auto;
}

.make-canvas {
    width: 100%;
    display: flex;
}

.image-frame {
    position: relative;
    width: 40%;
    max-width: 450px;
    max-height: 900px;
    margin: auto;
    display: flex;
    align-items: center;
    overflow: hidden;

}


.image-frame img {
    width: 100%;
    z-index: 100;
}

.image-frame img.append-image-item {
    width: 10vw;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 50;
}

.make-canvas .make-console {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
}

.make-canvas .make-console select {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    font-size: var(--font-size-0-9);
}


.make-canvas .make-console label {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-0-9);
    font-weight: 600;
}

.make-canvas .make-console label input {
    padding: 7px 13px;
    border-radius: 3px;
    font-size: var(--font-size-0-9);
}

.make-canvas .make-console > input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    font-size: var(--font-size-0-9);
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 5px;
}


.make-canvas .make-console input[type="color"] {
    height: 40px;
    padding: 5px;
}

.make-canvas .append-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    margin-bottom: 5px;

    font-size: var(--font-size-0-9);
}

.make-canvas .append-image div {
    display: flex;
    gap: 10px;
    font-size: var(--font-size-1);
}

.make-canvas .append-image div i {
    padding: 5px;
    cursor: pointer;
}


.make-canvas .image-view img {
    width: 100%;
    max-width: 450px;
    max-height: 450px;
    object-fit: contain;
}

.make-canvas input[type="range"] {
    padding: 10px;

}

.make-canvas [id^='slider-form'] {
    display: none;
}

.cropper-input {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.cropper-input input[type='file'] {
    padding: 5px 13px;
    flex: 1;
    box-sizing: border-box;
    width: 60%;


}

.cropper-input input[type='button'] {
    padding: 7px 13px;
}

@media (max-width: 480px) {


    .image-frame {

        width: 94%;
    }

    .make-canvas {
        display: block;
    }

    .make-canvas .image-view {
        width: 100%;
        box-sizing: border-box;
    }
}
