.fileList {
    li {
        box-shadow: inset 0 0 2px 1px var(--border-color);
        text-align: left;
        font-size: var(--font-size-0-8);
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        padding: 3px 7px;
        margin: 3px 0;

        p {
            flex: 1
        }

        input {
            width: auto;
            padding: 8px 12px;
            font-size: var(--font-size-0-8);
        }

    }
}