
.active, .normal{
  padding: 6px 6px 5px 6px;

  transition: all 0.1s ease;
  margin: 0 2px;
}

.active:hover,.normal:hover{
  color: var(--hover-color-4);
}
.normal{
  color: var(--color-3);
  font-size: var(--font-size-1-1);
  display: flex;
  align-items: center;
}

.active{
  background-color: var(--bg-color-6);
  color: var(--color-2);
  font-size: var(--font-size-1);
}