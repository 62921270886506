.pageBar {
    margin: 20px auto;

    ul {
        display: flex;
        justify-content: center;
    }

    a {
        cursor: pointer;
        width: 2.2rem;
        height: 2.2rem;
        border: 1px solid var(--border-color);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(0) !important;
        font-size: var(--font-size-0-9);

        &:hover {
            background-color: var(--hover-color);
        }

        &[id='current'] {
            font-weight: bold;
        }
    }
}



@media (max-width: 768px) {
    .pageBar a {
        width: 1.7rem;
        height: 1.7rem;
    }
}
